import { FC } from "react";
import classNames from "classnames";

import usePackageTotalPrice from "@hooks/use-package-total-price";

import {
  getPackageSubtitle,
  getPackageTemplatePriceTitle,
} from "@components/Package/utils";

import PublicBookingItem from "./PublicBookingItem";
import { ItemType } from "./PublicBookingSection";

interface PublicBookingPackageSectionProps {
  item: ItemType;
  userSlug?: string;
  editMode?: boolean;
}

const PublicBookingPackageSection: FC<PublicBookingPackageSectionProps> = ({
  item,
  userSlug,
  editMode,
}) => {
  const { total } = usePackageTotalPrice(item);

  const prefix = `/me/${userSlug}`;
  const link = `${prefix}/packages/${item.slug || item.id}`;
  const { icon, description: itemDescription, title } = item;
  const description = itemDescription;
  const currencyString = getPackageTemplatePriceTitle(item, total);
  const subtitle = getPackageSubtitle(item);

  return (
    <div
      className={classNames(
        "w-full rounded-2xl h-[218px]  bg-foreground/7 hover:bg-foreground/20",
        editMode ? "xl:h-[249px]" : "sm:h-[249px]"
      )}
      key={item.id}
    >
      <PublicBookingItem
        link={link}
        description={description}
        icon={icon}
        currencyString={currencyString}
        subtitle={subtitle as string}
        title={title}
        accounts={item.accounts}
        editMode={editMode}
      />
    </div>
  );
};

export default PublicBookingPackageSection;
