import React, { ReactNode } from "react";
import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
  Droppable,
} from "react-beautiful-dnd";
import classNames from "classnames";

type Props<T> = {
  onDragEnd: DragDropContextProps["onDragEnd"];
  onDragStart?: DragDropContextProps["onDragStart"];
  onDragUpdate?: DragDropContextProps["onDragUpdate"];
  itemClassname?: string;
  fields: T[];
  children: (field: T, index: number) => ReactNode;
  isCombineEnabled?: boolean;
  getItemClassname?: (field: T) => string;
  isDragDisabled?: boolean;
  isSortDisabled?: boolean;
  wrapperClassname?: string;
} & Omit<DragDropContextProps, "children">;

const getStyle = (style, snapshot, isSortDisabled) => {
  if (!isSortDisabled) return style;

  if (!snapshot.isDragging) return {};
  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style,
    // cannot be 0, but make it super tiny
    transitionDuration: `0.001s`,
  };
};

const FormDragAndDrop = <
  T extends {
    id?: string;
    isDragDisabled?: boolean;
  },
>({
  wrapperClassname,
  onDragEnd,
  onDragUpdate,
  onDragStart,
  isDragDisabled,
  itemClassname = "",
  fields,
  children,
  isCombineEnabled,
  getItemClassname,
  isSortDisabled = false,
}: Props<T>) => (
  <DragDropContext
    onDragUpdate={onDragUpdate}
    onDragStart={onDragStart}
    onDragEnd={onDragEnd}
  >
    <Droppable isCombineEnabled={isCombineEnabled} droppableId="droppable">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <ul className={wrapperClassname}>
            {fields.map((field, index) => (
              <Draggable
                key={field.id}
                isDragDisabled={field.isDragDisabled || isDragDisabled}
                draggableId={field.id}
                index={index}
              >
                {(provided, snapshot) => (
                  <li
                    key={field.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classNames(
                      itemClassname,
                      getItemClassname && getItemClassname(field)
                    )}
                    tabIndex={-1}
                    style={getStyle(
                      provided.draggableProps.style,
                      snapshot,
                      isSortDisabled
                    )}
                  >
                    {children(field, index)}
                  </li>
                )}
              </Draggable>
            ))}
            <span className={classNames(isSortDisabled && "hidden")}>
              {provided.placeholder}
            </span>
          </ul>
        </div>
      )}
    </Droppable>
  </DragDropContext>
);

export default FormDragAndDrop;
