import { FC } from "react";
import { DragDropContextProps } from "react-beautiful-dnd";

import { useProfileData } from "@hooks/useProfileData";

import { AddButton } from "@components/Buttons";
import FormDragAndDrop from "@components/FormDragAndDrop";
import PackagePicker from "@components/PackagePicker";
import { PublicProfileSidebarPageProps } from "@components/PublicProfile/PublicProfileSidebar/Pages";
import PublicProfileSidebarSection from "@components/PublicProfile/PublicProfileSidebarSection";

import {
  getHiddenItems,
  getItemsAfterDrag,
  getShownItems,
  MemberItemType,
  updateHiddenStatus,
  updateMemberItemsOrder,
  updateMemberItemStatus,
} from "../utils";

import PublicItemCard, { PublicItemType } from "./PublicItemCard";
import SectionTitle from "./SectionTitle";
import SidebarPageEmptyState from "./SidebarPageEmptyState";

const PackagesPage: FC<PublicProfileSidebarPageProps> = ({
  isMemberPage = false,
}) => {
  const { data, updateData, isNestedMember } = useProfileData(isMemberPage);
  const isMember = isMemberPage || isNestedMember;

  const packages = data?.packages as PublicItemType[];

  if (packages?.length === 0)
    return (
      <SidebarPageEmptyState>
        No packages to show. Create packages from the services tab.
      </SidebarPageEmptyState>
    );

  const shown = getShownItems(
    packages,
    isMember,
    data?.memberPackages
  ) as PublicItemType[];
  const hiddenPackages = getHiddenItems(
    packages,
    isMember,
    data?.memberPackages
  );

  const onDragEnd: DragDropContextProps["onDragEnd"] = (result) => {
    if (!result.destination) return; // dropped outside the list
    const newPackages = getItemsAfterDrag(
      result.source.index,
      result.destination.index,
      shown,
      packages
    ) as PublicItemType[];
    if (newPackages) {
      if (isMember) {
        const memberItems = updateMemberItemsOrder(
          newPackages,
          data?.memberPackages || []
        ) as MemberItemType[];
        updateData({ memberPackages: memberItems });
      } else {
        updateData({ packages: newPackages });
      }
    }
  };

  const onUpdateHiddenStatus = (
    packageItem: PublicItemType,
    newStatus: boolean
  ) => {
    const newItems = updateHiddenStatus(
      packageItem,
      newStatus,
      packages
    ) as PublicItemType[];
    if (newItems) {
      if (isMember) {
        const memberItems = updateMemberItemStatus(
          packageItem,
          newStatus,
          data?.memberPackages || []
        );
        updateData({ memberPackages: memberItems });
      } else {
        updateData({ packages: newItems });
      }
    }
  };

  return (
    <PublicProfileSidebarSection title="Packages">
      {shown?.length > 0 && (
        <PublicProfileSidebarSection title="Section title" level={2}>
          <SectionTitle
            type="packages"
            placeholder="Start a package"
            isMemberPage={isMemberPage}
          />
        </PublicProfileSidebarSection>
      )}
      <div>
        <FormDragAndDrop
          onDragEnd={onDragEnd}
          fields={shown || []}
          itemClassname="w-full mb-2 rounded-lg"
        >
          {(field, index) => {
            return (
              <PublicItemCard
                key={`packages-${index}`}
                item={field as PublicItemType}
                onUpdateHiddenStatus={onUpdateHiddenStatus}
                type="package"
              />
            );
          }}
        </FormDragAndDrop>
        {hiddenPackages?.length > 0 && (
          <PackagePicker
            onPackageChange={(p) =>
              onUpdateHiddenStatus(p as PublicItemType, false)
            }
            label={null}
            customPlaceholder={
              <AddButton className="py-6 hover:bg-grey-900 bg-grey-950">
                Add a package
              </AddButton>
            }
            packages={hiddenPackages as PublicItemType[]}
            className="!mt-2 shadow-none"
          />
        )}
      </div>
    </PublicProfileSidebarSection>
  );
};

export default PackagesPage;
